export const Text = ({ props }) => (
  <svg
    width="110"
    height="110"
    id="text"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 200 200"
    overflow="visible"
  >
    <g>
      <path
        fill="#FFFFFF"
        d="M18.4,54.7c-1.8-0.8-2.8-0.1-3.2,0.7c-0.6,1.1-0.2,2.1,1.1,2.8c3.5,1.8,8.9-4.4,14.5-1.5
      c3.4,1.8,4.1,5,2.3,8.3c-1.7,3.3-5.3,3.8-8.4,2.3l1.3-3.6c2.1,0.9,3.6,0.7,4.2-0.5c0.6-1.2,0.4-2.4-0.8-3
      c-4.1-2.1-8.9,4.4-14.7,1.4c-3.3-1.7-4.1-4.7-2.2-8.1c1.5-2.9,4.5-3.5,7.5-2.3L18.4,54.7z"
      />
      <path
        fill="#FFFFFF"
        d="M29,36l-2.6,2.8l-2.5-2.4l8-8.5l2.5,2.4l-2.6,2.8L46,46.6l-2.7,2.9L29,36z"
      />
      <path
        fill="#FFFFFF"
        d="M59.4,35.8l-3.4,2L44.3,18l4.8-2.8c3.8-2.3,6.7-1.8,9.3,2.4c1.9,3.2,1.4,5.2,0.6,6.4l8.2,7.3l-3.5,2.1
      l-7.3-6.8c-0.4,0.3-1.1,0.8-1.7,1.1L59.4,35.8z M53,24.9l1.2-0.7c1.8-1.1,2.1-2.2,0.7-4.6c-1.4-2.4-2.5-2.6-4.3-1.6l-1.2,0.7
      L53,24.9z"
      />
      <path
        fill="#FFFFFF"
        d="M78.8,27.1L75.1,28L73.6,4.6l4.8-1.2l9.7,21.4l-3.7,0.9l-1.9-4.8l-4.2,1.1L78.8,27.1z M78,18.4l3.1-0.8
      l-3.9-9.2l-0.1,0L78,18.4z"
      />
      <path
        fill="#FFFFFF"
        d="M98.3,4.5l-3.8,0l0-3.5l11.6,0l0,3.5l-3.8,0l-0.1,19.6l-4,0L98.3,4.5z"
      />
      <path
        fill="#FFFFFF"
        d="M115.5,25.5l6.2-22.2l10.2,2.9L131,9.5l-6.4-1.8l-1.6,5.7l4.9,1.4l-0.9,3.3l-4.9-1.4l-1.8,6.5l6.8,1.9
      l-0.9,3.3L115.5,25.5z"
      />
      <path
        fill="#FFFFFF"
        d="M146.3,25.7l5.5,3.5l-6.3,9.9l-1.6-1l0.3-1.9c-1.8,0.6-2.9,0.6-4.5-0.4c-2.7-1.7-3.3-4.7-1.3-7.8l6.4-10.1
      c1.7-2.8,4.8-4,8.1-1.9c3.4,2.1,3.5,5.6,1.6,8.5l-0.8,1.2l-3.2-2c1.8-2.9,1.7-4.1,0.4-4.9c-0.8-0.5-1.8-0.5-2.8,1.2l-6.3,10
      c-0.8,1.2-0.9,2.3,0.3,3c1.4,0.9,2.3,0.4,3.5-1.5l1.2-1.9l-2.1-1.4L146.3,25.7z"
      />
      <path fill="#FFFFFF" d="M154.3,47l17-15.7l2.7,2.9l-17,15.7L154.3,47z" />
      <path
        fill="#FFFFFF"
        d="M164.1,59.1L184.4,48l5.1,9.3l-3,1.7l-3.2-5.8L178,56l2.4,4.5l-3,1.7l-2.4-4.5l-6,3.2l3.4,6.2l-3,1.7
      L164.1,59.1z"
      />
      <path
        fill="#FFFFFF"
        d="M190.4,113.1l1.8,0.2c1.9,0.2,2.9-0.5,3-1.6c0.2-1.6-0.6-2.2-2.3-2.4l-11.6-1.4c-1.7-0.2-2.6,0.2-2.8,1.8
      c-0.2,1.4,0.6,2,2.1,2.2l2.8,0.3l-0.5,3.9l-2.6-0.3c-3.2-0.4-5.6-2.6-5.2-6.6c0.5-4.1,3.4-5.7,6.6-5.3l11.8,1.5
      c3.2,0.4,5.6,2.6,5.1,6.8c-0.5,3.9-3.6,5.5-7,5.1l-1.7-0.2L190.4,113.1z"
      />
      <path
        fill="#FFFFFF"
        d="M187.6,132.9c3,1.3,4.6,4.2,2.9,8c-1.7,3.8-4.9,4.4-7.9,3.1l-10.8-4.9c-3-1.3-4.6-4.2-2.9-8
      c1.7-3.8,4.9-4.4,7.9-3.1L187.6,132.9z M175.2,131.7c-1.6-0.7-2.5-0.6-3.2,0.9s-0.1,2.3,1.5,3l10.7,4.8c1.6,0.7,2.5,0.6,3.2-0.9
      c0.7-1.5,0.1-2.3-1.5-3L175.2,131.7z"
      />
      <path
        fill="#FFFFFF"
        d="M178,161.3l-3.9,4.4c-2.7,3.1-5.8,3.2-9.4,0.1l-6.8-6.1c-3.5-3.1-3.8-6.3-1-9.3l3.9-4.4L178,161.3z
       M160.5,151l-0.9,1c-1.4,1.6-1.3,2.7,0.5,4.3l7.6,6.7c1.8,1.6,2.9,1.6,4.3,0l0.9-1L160.5,151z"
      />
      <path
        fill="#FFFFFF"
        d="M144.2,161.9l12.1,19.7l-9.1,5.6l-1.8-2.9l5.7-3.5l-3.1-5.1l-4.4,2.7l-1.8-2.9l4.4-2.7l-3.6-5.8l-6.1,3.7
      l-1.8-2.9L144.2,161.9z"
      />
      <path
        fill="#FFFFFF"
        d="M95.6,199.1l-5.9-0.6c-4.1-0.4-6-2.9-5.5-7.6l1-9.1c0.5-4.7,2.9-6.7,7-6.3l5.9,0.6L95.6,199.1z M93.8,178.9
      l-1.4-0.1c-2.1-0.2-2.9,0.5-3.2,2.9l-1.1,10.1c-0.3,2.4,0.4,3.3,2.5,3.5l1.4,0.1L93.8,178.9z"
      />
      <path
        fill="#FFFFFF"
        d="M75.5,172l-8.9,21.3l-9.8-4.1l1.3-3.2l6.1,2.6l2.3-5.5l-4.7-2l1.3-3.2l4.7,2l2.6-6.3l-6.6-2.7l1.3-3.2
      L75.5,172z"
      />
      <path
        fill="#FFFFFF"
        d="M39.9,171.4c-1.1,1.6-0.7,2.8,0,3.3c1,0.8,2.1,0.6,3-0.5c2.5-3.1-2.6-9.6,1.3-14.5c2.4-3,5.7-3,8.6-0.7
      c2.9,2.3,2.7,5.9,0.6,8.7l-3.3-1.9c1.3-1.9,1.3-3.4,0.3-4.2c-1.1-0.9-2.3-0.9-3.1,0.2c-2.9,3.6,2.7,9.6-1.5,14.7
      c-2.3,2.9-5.4,3.1-8.4,0.6c-2.5-2-2.6-5-0.8-7.8L39.9,171.4z"
      />
      <path
        fill="#FFFFFF"
        d="M40.6,147.3l-18.5,13.9l-2.4-3.2l18.5-13.9L40.6,147.3z"
      />
      <path
        fill="#FFFFFF"
        d="M19.1,133.6l-2.5-6l10.8-4.4l0.7,1.8l-1.3,1.3c1.8,0.6,2.7,1.2,3.4,2.9c1.2,2.9-0.1,5.8-3.5,7.2l-11,4.5
      c-3,1.2-6.2,0.5-7.7-3.2c-1.5-3.7,0.4-6.6,3.6-7.9l1.4-0.6l1.4,3.5c-3.2,1.3-3.8,2.3-3.2,3.7c0.4,0.9,1.2,1.4,3,0.7l11-4.5
      c1.4-0.6,2.1-1.4,1.5-2.6c-0.6-1.5-1.7-1.7-3.8-0.8l-2.1,0.8l1,2.3L19.1,133.6z"
      />
      <path
        fill="#FFFFFF"
        d="M24.9,112.5l-23,2l-0.3-3.7l12.8-5.9l0-0.1l-13.2,1.1l-0.3-3.6l23-2l0.3,3.4l-14,6.3l0,0.1l14.4-1.2
      L24.9,112.5z"
      />
    </g>
  </svg>
);
