"use client";
import React, { useState, useEffect, useMemo } from "react";
import Image from "next/image";
import Logo from "../Logo";
import { ManxLabel } from "../ManxLabel";

const Hero = () => {
  const [scales, setScales] = useState({
    scale_0: 100,
    scale_1: 100,
    scale_2: 100,
    scale_3: 100,
    scale_4: 100,
    scale_5: 100,
  });

  // Wrap the initialization of 'speeds' in useMemo
  const speeds = useMemo(() => [0.03, 0.015, 0.01, 0.005, 0.003, 0.001], []);

  useEffect(() => {
    const animation = setInterval(() => {
      if (scales["scale_0"] < 100 + 4000 * speeds[0]) {
        setScales((prevScales) => {
          const newScales = {};
          for (let i = 0; i < speeds.length; i++) {
            const scaleKey = `scale_${i}`;
            newScales[scaleKey] = prevScales[scaleKey] + speeds[i];
          }
          return newScales;
        });
      }
    }, 15);

    return () => clearInterval(animation);
  }, [scales, speeds]);

  // const generateDynamicStyles = () => {
  //   const dynamicStyles = {};
  //   for (const [i, speed] of speeds.entries()) {
  //     const scaleKey = `scale_${i}`;
  //     dynamicStyles[`--prlx-${i}`] = `${scales[scaleKey]}%`;
  //   }
  //   return dynamicStyles;
  // };

  let loaded = false;
  // TODO: set loaded = true when images were loaded



  return (
    <>
      <header className={`manx-hero${loaded ? ' loaded' : ''}`} id="hero">

        <div className="moving-zeppelin">
          <Image
            src="/images/ship_0212.webp"
            width={612}
            height={612}
            alt="zepellin"
            loading="lazy"
          />
        </div>

        <div width={3500} height={1337} className="manx-hero__bg manx-hero__bg-5"></div>
        <div width={3500} height={1337} className="manx-hero__bg manx-hero__bg-4"></div>
        <div width={3500} height={1337} className="manx-hero__bg manx-hero__bg-3"></div>
        <div width={3500} height={1337} className="manx-hero__bg manx-hero__bg-2"></div>
        <div width={3500} height={1337} className="manx-hero__bg manx-hero__bg-1"></div>

        <div className="manx-hero__content">
          <Logo classes="manx-hero__logo" width="150" height="25" />

          <div className="manx-hero--wrap">
            <h1 className="manx-hero__headline">
              Wir sind die Crew für maß&shy;geschneiderte Reisen durch die digitale
              Transformation.
            </h1>
            <div className="manx-hero__detail">
              <ManxLabel classes="manx-hero__sub-brand" />
            </div>
          </div>
        </div>
      </header>

    </>
  );
};

export default Hero;
