import Image from "next/image";
import Link from "next/link";
import React from "react";

const Logo = ({ classes, width, height }) => {
  return (
    <div className={`manx-header__logo ${classes ? classes : ""}`}>
      <Link href="/">
        <Image width={width} height={height} src="/logo.svg" alt="brand" />
      </Link>
    </div>
  );
};

export default Logo;
