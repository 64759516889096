import Head from "next/head";
import { HOME_OG_IMAGE_URL } from "../../lib/constants";

export default function Meta() {
  return (
    <Head>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta
        name="description"
        content="Manx-Design GmbH: vielfach ausgezeichnete Agentur in Essen/Ruhrgebiet für Branding, Markenführung, Grafikdesign, Werbung, Webseiten und Corporate Design"
      />
      <meta
        name="keywords"
        content="Manx-Design, Branding, Markenführung, Grafikdesign, Werbung, Webseiten, Corporate Design, Essen, Ruhrgebiet, Kreative Agentur"
      />
      {/* <!-- Apple Mobile Settings --> */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black" />

      {/* <!-- Theme Color --> */}
      <meta name="theme-color" content="#111" />

      {/* <!-- Open Graph (OG) Tags --> */}
      <meta property="og:site_name" content="Manx Design" />
      <meta property="og:title" content="Manx Webagentur | Home" />
      <meta property="og:url" content="/" />
      <meta property="og:image" content="/meta/og-image.webp" />
      <meta
        property="og:description"
        content="Manx-Design GmbH: vielfach ausgezeichnete Agentur in Essen/Ruhrgebiet für Branding, Markenführung, Grafikdesign, Werbung, Webseiten und Corporate Design"
      />
      <meta property="og:type" content="website" />

      {/* <!-- Twitter Card Tags --> */}
      <meta name="twitter:title" content="Manx Webagentur | Home" />
      <meta
        name="twitter:description"
        content="Manx-Design GmbH: vielfach ausgezeichnete Agentur in Essen/Ruhrgebiet für Branding, Markenführung, Grafikdesign, Werbung, Webseiten und Corporate Design"
      />
      <meta name="twitter:image" content="/meta/og-image.webp" />
      <meta name="twitter:card" content="summary_large_image" />

      {/* Preload: Production add: https://manx.de/ */}
      <link
        href="/fonts/tradegothic/TradeGothicProCondensed20.woff"
        as="font"
        type="font/woff"
        crossorigin
      />
      {/* <!-- Icons --> */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/meta/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/meta/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/meta/favicon-16x16.png"
      />
      <link rel="manifest" href="/meta/manifest.json" />
      <link
        rel="mask-icon"
        color="#000000"
        href="/meta/safari-pinned-tab.svg"
      />
      <link rel="shortcut icon" href="/meta/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ff575d" />
      <meta name="msapplication-TileImage" content="/meta/mstile-144x144.png" />
      <meta name="msapplication-config" content="/meta/browserconfig.xml" />

      {/* <!-- Other Tags --> */}
      <meta name="pinterest" content="nopin" />

      <link rel="shortcut icon" href="/favicon.png" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      <meta name="theme-color" content="#000" />
      <link rel="alternate" type="application/rss+xml" href="/feed.xml" />
      <meta
        name="description"
        content={`We organize and curate cultural events in Berlin, we aim in democratizing even more the musical scene in Berlin.`}
      />
      <meta property="og:image" content={HOME_OG_IMAGE_URL} />
    </Head>
  );
}
