export const CMS_NAME = "Manx Webagentur | agentur in Essen";
export const CMS_ABOUT =
  "We throw parties/festivals and transformative experiences for both artists and audience  - Events | Maloca";
export const CMS_URL = "https://manx.de/";
export const CMS_KEYWORDS =
  "Manx-Design, Branding, Markenführung, Grafikdesign, Werbung, Webseiten, Corporate Design, Essen, Ruhrgebiet, Kreative Agentur";
export const CMS_DESC =
  "Manx-Design GmbH: vielfach ausgezeichnete Agentur in Essen/Ruhrgebiet für Branding, Markenführung, Grafikdesign, Werbung, Webseiten und Corporate Design";
// banner.
export const HOME_OG_IMAGE_URL = "https://dev.manx.de/meta/og-image.webp";
