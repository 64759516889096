export const Borders = ({ props }) => (
  <svg
    id="borders"
    viewBox="0 0 200 200"
    width="70"
    height="70"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle id="circle" cx="100" cy="100" r="98" />
  </svg>
);
