import { Borders } from "./Borders";
import { Text } from "./Text";

export const ManxLabel = ({ classes }) => (
  <>
    <div className={`manx-label ${classes ? classes : ""}`}>
      <Borders />
      <Text />
    </div>
  </>
);
