"use client";
import Hero from "../components/Hero";
import Meta from "../components/Meta";
import Head from "next/head";
import { CMS_NAME } from "../lib/constants";
import dynamic from "next/dynamic";
 

const pageTitle = Array.isArray(CMS_NAME) ? CMS_NAME.join(" ") : CMS_NAME;

const DynamicAccordion = dynamic(() => import("../components/Accordion"), {
  suspense: true,
  ssr: false,
});

const DynamicFooter = dynamic(() => import("../components/Footer"), {
  suspense: true,
  ssr: false,
});

export default function Home() {
  return (
    <>
      <Head>
        <title>{`${pageTitle} | Home`}</title>
      </Head>
      <Meta />

      <main className="manx-home manx-home__main decorative" id="home">
        <Hero />

        <section className="manx-home__accordion">
          <DynamicAccordion />
        </section>
        {/* <PortfolioPage /> */}
        <DynamicFooter />
      </main>
    </>
  );
}
